exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/about-us.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-blog-blog-post-tsx": () => import("./../../../src/templates/blog/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-blog-post-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-industries-tsx": () => import("./../../../src/templates/industries.tsx" /* webpackChunkName: "component---src-templates-industries-tsx" */),
  "component---src-templates-join-us-job-offer-tsx": () => import("./../../../src/templates/join-us/job-offer.tsx" /* webpackChunkName: "component---src-templates-join-us-job-offer-tsx" */),
  "component---src-templates-join-us-join-us-tsx": () => import("./../../../src/templates/join-us/join-us.tsx" /* webpackChunkName: "component---src-templates-join-us-join-us-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/templates/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */),
  "component---src-templates-products-product-tsx": () => import("./../../../src/templates/products/product.tsx" /* webpackChunkName: "component---src-templates-products-product-tsx" */),
  "component---src-templates-products-products-tsx": () => import("./../../../src/templates/products/products.tsx" /* webpackChunkName: "component---src-templates-products-products-tsx" */),
  "component---src-templates-projects-tsx": () => import("./../../../src/templates/projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */),
  "component---src-templates-services-service-tsx": () => import("./../../../src/templates/services/service.tsx" /* webpackChunkName: "component---src-templates-services-service-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-software-development-tsx": () => import("./../../../src/templates/software-development.tsx" /* webpackChunkName: "component---src-templates-software-development-tsx" */),
  "slice---src-components-about-us-about-us-our-story-tsx": () => import("./../../../src/components/about_us/about_us_our_story.tsx" /* webpackChunkName: "slice---src-components-about-us-about-us-our-story-tsx" */),
  "slice---src-components-about-us-about-us-values-tsx": () => import("./../../../src/components/about_us/about_us_values.tsx" /* webpackChunkName: "slice---src-components-about-us-about-us-values-tsx" */),
  "slice---src-components-contact-contact-form-done-tsx": () => import("./../../../src/components/contact/contact-form-done.tsx" /* webpackChunkName: "slice---src-components-contact-contact-form-done-tsx" */),
  "slice---src-components-contact-contact-form-tsx": () => import("./../../../src/components/contact/contact-form.tsx" /* webpackChunkName: "slice---src-components-contact-contact-form-tsx" */),
  "slice---src-components-contact-contact-hero-wrapper-tsx": () => import("./../../../src/components/contact/contact-hero-wrapper.tsx" /* webpackChunkName: "slice---src-components-contact-contact-hero-wrapper-tsx" */),
  "slice---src-components-contact-contact-info-tsx": () => import("./../../../src/components/contact/contact-info.tsx" /* webpackChunkName: "slice---src-components-contact-contact-info-tsx" */),
  "slice---src-components-contact-contact-offices-tsx": () => import("./../../../src/components/contact/contact-offices.tsx" /* webpackChunkName: "slice---src-components-contact-contact-offices-tsx" */),
  "slice---src-components-industries-industries-header-tsx": () => import("./../../../src/components/industries/industries_header.tsx" /* webpackChunkName: "slice---src-components-industries-industries-header-tsx" */),
  "slice---src-components-industries-industries-widget-tsx": () => import("./../../../src/components/industries/industries_widget.tsx" /* webpackChunkName: "slice---src-components-industries-industries-widget-tsx" */),
  "slice---src-components-info-section-info-section-tsx": () => import("./../../../src/components/info-section/info-section.tsx" /* webpackChunkName: "slice---src-components-info-section-info-section-tsx" */),
  "slice---src-components-join-us-join-us-baner-tsx": () => import("./../../../src/components/join_us/join_us_baner.tsx" /* webpackChunkName: "slice---src-components-join-us-join-us-baner-tsx" */),
  "slice---src-components-join-us-join-us-form-done-tsx": () => import("./../../../src/components/join_us/join_us_form_done.tsx" /* webpackChunkName: "slice---src-components-join-us-join-us-form-done-tsx" */),
  "slice---src-components-join-us-join-us-form-tsx": () => import("./../../../src/components/join_us/join_us_form.tsx" /* webpackChunkName: "slice---src-components-join-us-join-us-form-tsx" */),
  "slice---src-components-join-us-join-us-our-culture-tsx": () => import("./../../../src/components/join_us/join_us_our_culture.tsx" /* webpackChunkName: "slice---src-components-join-us-join-us-our-culture-tsx" */),
  "slice---src-components-join-us-join-us-reasons-tsx": () => import("./../../../src/components/join_us/join_us_reasons.tsx" /* webpackChunkName: "slice---src-components-join-us-join-us-reasons-tsx" */),
  "slice---src-components-join-us-join-us-work-offers-tsx": () => import("./../../../src/components/join_us/join_us_work_offers.tsx" /* webpackChunkName: "slice---src-components-join-us-join-us-work-offers-tsx" */),
  "slice---src-components-our-tech-stack-section-tsx": () => import("./../../../src/components/our-tech-stack-section.tsx" /* webpackChunkName: "slice---src-components-our-tech-stack-section-tsx" */),
  "slice---src-components-products-product-product-functionalities-tsx": () => import("./../../../src/components/products/product/product-functionalities.tsx" /* webpackChunkName: "slice---src-components-products-product-product-functionalities-tsx" */),
  "slice---src-components-shared-breadcrumbs-tsx": () => import("./../../../src/components/shared/breadcrumbs.tsx" /* webpackChunkName: "slice---src-components-shared-breadcrumbs-tsx" */),
  "slice---src-components-shared-footer-tsx": () => import("./../../../src/components/shared/footer.tsx" /* webpackChunkName: "slice---src-components-shared-footer-tsx" */),
  "slice---src-components-shared-get-in-touch-tsx": () => import("./../../../src/components/shared/get-in-touch.tsx" /* webpackChunkName: "slice---src-components-shared-get-in-touch-tsx" */),
  "slice---src-components-shared-header-header-tsx": () => import("./../../../src/components/shared/header/header.tsx" /* webpackChunkName: "slice---src-components-shared-header-header-tsx" */),
  "slice---src-components-shared-landing-section-tsx": () => import("./../../../src/components/shared/landing-section.tsx" /* webpackChunkName: "slice---src-components-shared-landing-section-tsx" */),
  "slice---src-components-shared-our-clients-logos-section-tsx": () => import("./../../../src/components/shared/our-clients-logos-section.tsx" /* webpackChunkName: "slice---src-components-shared-our-clients-logos-section-tsx" */),
  "slice---src-components-shared-our-services-widget-tsx": () => import("./../../../src/components/shared/our_services_widget.tsx" /* webpackChunkName: "slice---src-components-shared-our-services-widget-tsx" */),
  "slice---src-components-shared-products-random-products-tsx": () => import("./../../../src/components/shared/products/random-products.tsx" /* webpackChunkName: "slice---src-components-shared-products-random-products-tsx" */),
  "slice---src-components-shared-services-random-services-tsx": () => import("./../../../src/components/shared/services/random-services.tsx" /* webpackChunkName: "slice---src-components-shared-services-random-services-tsx" */),
  "slice---src-components-small-portfolio-section-tsx": () => import("./../../../src/components/small-portfolio-section.tsx" /* webpackChunkName: "slice---src-components-small-portfolio-section-tsx" */),
  "slice---src-components-team-info-section-team-info-section-tsx": () => import("./../../../src/components/team-info-section/team-info-section.tsx" /* webpackChunkName: "slice---src-components-team-info-section-team-info-section-tsx" */)
}

